import React from 'react';
import { useNavigate } from 'react-router-dom';
import { v1 as generateId } from 'uuid';

import {
  trackWithMixpanel,
  useLanguage,
  useCurrentRoute,
  isDefined,
} from '../../utils';
import { useAppDispatch } from '../../store/utils/hooks';
import {
  AppRoute,
  AssistantProps,
  FetchingStatus,
  JobWithPrediction,
  MixpanelEventType,
  OpenJobsParams,
  QuestionSource,
} from '../../types';
import { selectEquipmentTypes } from '../../store/slices/inputs.slice';
import { askQuestion } from '../../store/slices/streaming.slice';
import Responsive from '../Responsive/Responsive';
import OpenJobsDesktop from './Desktop/OpenJobsDesktop';
import OpenJobsMobile from './Mobile/OpenJobsMobile';

type Props = AssistantProps &
  OpenJobsParams & {
    jobs: JobWithPrediction[] | null;
    status: FetchingStatus;
    height?: number;
  };

const OpenJobs: React.FC<Props> = ({
  jobs,
  status,
  totalElements,
  currentPage,
  filter,
  sorting,
  pageSize,
  onFilterChange,
  onPageChange,
  onPageSizeChange,
  onSortingChange,
  isMocking,
  httpUrl,
  wsUrl,
  environment,
  logError,
  logInfo,
}) => {
  const { language } = useLanguage();
  const dispatch = useAppDispatch();

  const currentRoute = useCurrentRoute();
  const navigate = useNavigate();

  const handleJobClicked = (job: JobWithPrediction) => {
    if (job.equipmentType === null || job.reportedSymptom === null) {
      return;
    }
    const conversationId = generateId();
    const equipmentType = isDefined(job.equipmentCategory)
      ? `${job.equipmentCategory}###${job.equipmentType}`
      : job.equipmentType;
    dispatch(
      selectEquipmentTypes({
        selectedTags: [
          {
            name: equipmentType,
            fullName: equipmentType,
            level: 1,
            input: [equipmentType],
          },
        ],
        selectedEquipmentType: equipmentType,
      }),
    );
    dispatch(
      askQuestion({
        conversationId,
        wsUrl,
        httpUrl,
        language,
        isMocking,
        environment,
        logError,
        logInfo,
        equipmentType,
        allEquipmentTags: [equipmentType],
        message: job.reportedSymptom,
        source: QuestionSource.OpenJob,
      }),
    );
    trackWithMixpanel({
      environment,
      event: {
        name:
          currentRoute === AppRoute.Customer
            ? MixpanelEventType.CustomerOpenJobClicked
            : MixpanelEventType.OpenJobClicked,
        properties: { id: job.id, symptom: job.reportedSymptom },
      },
    });
    navigate(`${AppRoute.Conversations}/${conversationId}`);
  };

  return (
    <Responsive
      desktopComponent={
        <OpenJobsDesktop
          onJobClicked={handleJobClicked}
          jobs={jobs}
          status={status}
          totalElements={totalElements}
          pageSize={pageSize}
          currentPage={currentPage}
          filter={filter}
          sorting={sorting}
          onSortingChange={onSortingChange}
          onFilterChange={onFilterChange}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
        />
      }
      mobileComponent={
        <OpenJobsMobile
          onJobClicked={handleJobClicked}
          jobs={jobs}
          status={status}
          totalElements={totalElements}
          pageSize={pageSize}
          currentPage={currentPage}
          filter={filter}
          sorting={sorting}
          onSortingChange={onSortingChange}
          onFilterChange={onFilterChange}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
        />
      }
    />
  );
};

export default OpenJobs;
