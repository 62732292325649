import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { v1 as generateId } from 'uuid';

import {
  EnvironmentName,
  EquipmentSession,
  FeedbackReason,
  FeedbackType,
  FetchingStatus,
  MixpanelEventType,
  QuestionSource,
  TroubleshootingStatus,
} from '../../types';
import { ApiThunkParams, AppThunkConfig } from '../store';
import {
  getJwtToken,
  isDefined,
  trackWithMixpanel,
  getAuthDataFromStorage,
  processStreamingPartials,
  getSecondsSinceLastQuestion,
} from '../../utils';
import {
  AnswerPartialResponse,
  ChatbotResponseType,
  CustomerSummaryPartialResponse,
  SessionChatbotQueryPayload,
  WebSocketQueryAction,
  wsClient,
} from '../../integration/webSocketClient';
import {
  disableWSMockServer,
  enableWSMockServer,
} from '../../mocks/web-socket-mock-server';
import { sendFeedback } from '../../integration/feedback.api';
import { SupportedLanguage } from '../../translations/common';
import { fetchMedia } from '../../integration/media.api';
import {
  addRootCausePartial,
  createEquipmentSession,
  updateEquipmentSession,
} from './conversations.slice';
import {
  addCustomerSummaryPartial,
  updateCustomerSession,
} from './customerDetails.slice';
import { getSuggestions } from './suggestions.slice';
import { getExtractedSymptom } from '../actions/extractedSymptom.actions';
import { getEquipmentPastJobs } from '../actions/pastJobs.actions';

export const STREAMING_KEY = 'streaming';

export interface StreamingState {
  initStatus: FetchingStatus;
}

const initialState: StreamingState = {
  initStatus: FetchingStatus.IDLE,
};

const processRootCausePartials = (
  parts: AnswerPartialResponse[],
  currentSessionId: string,
): string => {
  const relevantParts = parts.filter(
    (part) => part.sessionId === currentSessionId,
  );

  return processStreamingPartials(relevantParts);
};

const processCustomerSummaryPartials = (
  parts: CustomerSummaryPartialResponse[],
  currentCustomerId: string,
): string => {
  const relevantParts = parts.filter(
    (part) => part.customerId === currentCustomerId,
  );

  return processStreamingPartials(relevantParts);
};

interface InitializeStreamingParams {
  isMocking: boolean;
  wsUrl: string;
  httpUrl: string;
  logError: (msg: string) => void;
  logInfo: (msg: string) => void;
}

export const initializeStreaming = createAsyncThunk<
  void,
  InitializeStreamingParams,
  AppThunkConfig
>('initializeStreaming', async (params, { dispatch, getState }) => {
  const { isMocking, wsUrl, httpUrl, logError, logInfo } = params;
  const {
    streaming: { initStatus },
  } = getState();
  if (initStatus === FetchingStatus.PENDING) {
    return;
  }
  const token = getJwtToken();
  if (token === null) {
    throw new Error('Auth token not found, cannot initialize WS!');
  }
  let predictionsPartials: AnswerPartialResponse[] = [];
  let customerSummaryPartials: CustomerSummaryPartialResponse[] = [];
  dispatch(startWsInit());
  if (isMocking) {
    enableWSMockServer({ wsUrl, logError, logInfo });
  } else {
    disableWSMockServer({ wsUrl, logError, logInfo });
  }

  wsClient.close();
  await wsClient.init({
    ...params,
    token,
    handleResponse: (response) => {
      // The endpoint sometimes sends an automatic timeout a response, we can safely ignore it
      if (
        !isDefined(response.type) &&
        response.message === 'Endpoint request timed out'
      ) {
        return;
      }

      // Unexpected backend errors that were not emitted by the lambda
      if (
        !isDefined(response.type) &&
        response.message === 'Internal server error'
      ) {
        dispatch(setWsError());
        return;
      }

      switch (response.type) {
        case ChatbotResponseType.AnswerSources:
          dispatch(
            updateEquipmentSession({
              id: response.sessionId,
              answerJobIds: response.jobIds,
            }),
          );
          dispatch(
            getMedia({
              logError,
              baseUrl: httpUrl,
              mock: isMocking,
              sessionId: response.sessionId,
              jobIds: response.jobIds,
            }),
          );
          dispatch(
            getEquipmentPastJobs({
              logError,
              baseUrl: httpUrl,
              mock: isMocking,
              sessionId: response.sessionId,
              jobIds: response.jobIds,
            }),
          );
          break;
        case ChatbotResponseType.AnswerIntent:
          dispatch(
            updateEquipmentSession({
              id: response.sessionId,
              intent: response.intent,
            }),
          );
          break;
        case ChatbotResponseType.AnswerLanguage:
          dispatch(
            updateEquipmentSession({
              id: response.sessionId,
              language: response.language,
            }),
          );
          break;
        case ChatbotResponseType.AnswerPartial:
          predictionsPartials.push(response);
          dispatch(
            addRootCausePartial({
              sessionId: response.sessionId,
              partial: processRootCausePartials(
                predictionsPartials,
                response.sessionId,
              ),
            }),
          );
          break;
        case ChatbotResponseType.AnswerFinished:
          // Clearing the finished response parts from the cache
          predictionsPartials = predictionsPartials.filter(
            (part) => part.sessionId !== response.sessionId,
          );
          dispatch(
            updateEquipmentSession({
              id: response.sessionId,
              answerStatus: FetchingStatus.SUCCESS,
              answer: response.answer === '' ? null : response.answer,
              parts: response.parts || null,
            }),
          );
          break;
        case ChatbotResponseType.AnswerError:
          dispatch(
            updateEquipmentSession({
              id: response.sessionId,
              answer: null,
              answerJobIds: null,
              answerStatus: FetchingStatus.ERROR,
              parts: null,
              jobsStatus: FetchingStatus.SUCCESS,
              jobs: [],
              imagesStatus: FetchingStatus.SUCCESS,
              images: [],
            }),
          );
          break;
        case ChatbotResponseType.CustomerSummaryPartial:
          customerSummaryPartials.push(response);
          dispatch(
            addCustomerSummaryPartial({
              customerId: response.customerId,
              partial: processCustomerSummaryPartials(
                customerSummaryPartials,
                response.customerId,
              ),
            }),
          );
          break;
        case ChatbotResponseType.CustomerSummaryFinished:
          // Clearing the finished response parts from the cache
          customerSummaryPartials = customerSummaryPartials.filter(
            (part) => part.customerId !== response.customerId,
          );
          dispatch(
            updateCustomerSession({
              id: response.customerId,
              summaryStatus: FetchingStatus.SUCCESS,
              summary: response.answer === '' ? null : response.answer,
            }),
          );
          break;
        case ChatbotResponseType.CustomerSummaryError:
          dispatch(
            updateCustomerSession({
              id: response.customerId,
              summary: null,
              summaryStatus: FetchingStatus.ERROR,
            }),
          );
          break;
        default:
          params.logError(`Unknown WS message: ${JSON.stringify(response)}`);
      }
    },
  });
});

interface AskQuestionParams {
  conversationId: string;
  equipmentType: string;
  allEquipmentTags: string[];
  message: string;
  source: QuestionSource;
  language: SupportedLanguage;
  isMocking: boolean;
  wsUrl: string;
  httpUrl: string;
  environment: EnvironmentName;
  logError: (msg: string) => void;
  logInfo: (msg: string) => void;
}

export const askQuestion = createAsyncThunk<
  void,
  AskQuestionParams,
  AppThunkConfig
>('askQuestion', async (params, { getState, dispatch }) => {
  const {
    equipmentType,
    allEquipmentTags,
    language,
    message,
    source,
    isMocking,
    wsUrl,
    httpUrl,
    environment,
    logError,
    logInfo,
    conversationId,
  } = params;
  const authData = getAuthDataFromStorage();
  if (authData === null) {
    throw new Error('Not authenticated!');
  }
  const sessionId = generateId();
  const {
    streaming: { initStatus },
    conversations: { sessions },
  } = getState();
  const conversation = sessions.filter(
    (session) => session.conversationId === conversationId,
  );

  dispatch(
    createEquipmentSession({
      conversationId,
      sessionId,
      equipmentType,
      allEquipmentTags,
      question: message,
      troubleshootingOpen: shouldShowNextTroubleshooting(conversation),
    }),
  );
  dispatch(
    getSuggestions({
      equipmentType,
      language,
      sessionId,
      logError,
      question: message,
      baseUrl: httpUrl,
      mock: isMocking,
    }),
  );
  const isSent = wsClient.send<SessionChatbotQueryPayload>(
    WebSocketQueryAction.GetAnswer,
    {
      conversationId,
      sessionId,
      language,
      message,
      // As per #112 we want to send just the selected tag's full name, not the whole hierarchy
      equipmentTypes: [equipmentType],
      tenantId: authData.tenantId,
      token: authData.token,
    },
    logError,
  );
  dispatch(
    getExtractedSymptom({
      sessionId,
      logError,
      question: message,
      baseUrl: httpUrl,
      mock: isMocking,
    }),
  );

  if (!isSent && initStatus !== FetchingStatus.PENDING) {
    dispatch(
      initializeStreaming({
        logInfo,
        logError,
        wsUrl,
        httpUrl,
        isMocking,
      }),
    );
  }

  trackWithMixpanel({
    environment,
    event: {
      name: MixpanelEventType.QuestionAsked,
      properties: {
        source,
        question: message,
        secondsSinceLastQuestion: getSecondsSinceLastQuestion(sessions),
      },
    },
  });
});

// If the user closes the last troubleshooting in a conversation thread, we want to hide it in the next one by default.
// However, finishing a troubleshooting should not be counted as the user closing it.
const shouldShowNextTroubleshooting = (
  sessions: EquipmentSession[],
): boolean => {
  if (sessions.length === 0) {
    return true;
  }
  const latestSession = sessions[sessions.length - 1];

  return (
    latestSession.troubleshootingVisible ||
    latestSession.troubleshootingStatus === TroubleshootingStatus.Finished
  );
};

interface GetMediaParams extends ApiThunkParams {
  sessionId: string;
  jobIds: string[];
}

export const getMedia = createAsyncThunk<void, GetMediaParams, AppThunkConfig>(
  'getMedia',
  async (
    { sessionId, jobIds, baseUrl, mock, logError },
    { dispatch, signal },
  ) => {
    if (jobIds.length === 0) {
      dispatch(
        updateEquipmentSession({
          id: sessionId,
          images: [],
          imagesStatus: FetchingStatus.SUCCESS,
        }),
      );
      return;
    }
    const authData = getAuthDataFromStorage();
    if (authData === null) {
      throw new Error('Not authenticated!');
    }
    try {
      const images = await fetchMedia({
        jobIds,
        signal,
        baseUrl,
        mock,
      });
      dispatch(
        updateEquipmentSession({
          images,
          id: sessionId,
          imagesStatus: FetchingStatus.SUCCESS,
        }),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      logError(
        `Could not fetch images for session ${sessionId}, jobIds: ${jobIds.join(
          ', ',
        )}. Reason: ${error?.message}`,
      );
      dispatch(
        updateEquipmentSession({
          id: sessionId,
          imagesStatus: FetchingStatus.ERROR,
        }),
      );
    }
  },
);

interface SendFeedbackParams {
  session: EquipmentSession;
  httpUrl: string;
  isMocking: boolean;
  logError: (msg: string) => void;
}

export const sendPositiveFeedback = createAsyncThunk<
  void,
  SendFeedbackParams,
  AppThunkConfig
>(
  'sendPositiveFeedback',
  async ({ session, httpUrl, isMocking, logError }, { dispatch, signal }) => {
    sendFeedback({
      signal,
      baseUrl: httpUrl,
      mock: isMocking,
      sessionId: session.id,
      comment: '',
      reason: FeedbackReason.POSITIVE,
      useful: true,
    }).catch((error) =>
      logError(`Could not process process feedback. Reason: ${error?.message}`),
    );
    dispatch(
      updateEquipmentSession({
        id: session.id,
        feedback: {
          open: false,
          type: FeedbackType.POSITIVE,
        },
      }),
    );
  },
);

interface SendNegativeFeedbackParams extends SendFeedbackParams {
  reason: FeedbackReason;
  details: string;
}

export const sendNegativeFeedback = createAsyncThunk<
  void,
  SendNegativeFeedbackParams,
  AppThunkConfig
>(
  'sendNegativeFeedback',
  async (
    { details, reason, session, httpUrl, isMocking, logError },
    { dispatch, signal },
  ) => {
    sendFeedback({
      signal,
      reason,
      baseUrl: httpUrl,
      mock: isMocking,
      sessionId: session.id,
      comment: details,
      useful: false,
    }).catch((error) =>
      logError(
        `Could not process negative feedback. Reason: ${error?.message}`,
      ),
    );
    dispatch(
      updateEquipmentSession({
        id: session.id,
        feedback: {
          open: false,
          type: FeedbackType.NEGATIVE,
        },
      }),
    );
  },
);

const streamingSlice = createSlice({
  name: STREAMING_KEY,
  initialState,
  reducers: {
    startWsInit: (state) => ({
      ...state,
      initStatus: FetchingStatus.PENDING,
    }),
    setWsError: (state) => {
      return {
        ...state,
        initStatus: FetchingStatus.ERROR,
      };
    },
    reset: () => ({
      ...initialState,
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(initializeStreaming.fulfilled, (state) => {
        state.initStatus = FetchingStatus.SUCCESS;
      })
      .addCase(initializeStreaming.rejected, (state) => {
        state.initStatus = FetchingStatus.ERROR;
      });
  },
});

const { actions, reducer } = streamingSlice;

export const { startWsInit, setWsError, reset: resetWsState } = actions;

export const streamingReducer = reducer;
