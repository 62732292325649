import React from 'react';
import { useNavigate } from 'react-router-dom';
import { v1 as generateId } from 'uuid';

import { isDefined, useAuthData, useLanguage } from '../../../../../utils';
import {
  mockSearchExamples,
  SearchExample,
  searchExamplesPerTenant,
} from './searchExamplesPerTenant';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../store/utils/hooks';
import {
  ActiveFilter,
  selectEquipmentTypes,
  setActiveFilter,
} from '../../../../../store/slices/inputs.slice';
import { askQuestion } from '../../../../../store/slices/streaming.slice';
import { AppRoute, AssistantProps, QuestionSource } from '../../../../../types';
import styles from './SearchExamples.module.scss';

const SearchExamples: React.FC<AssistantProps> = ({
  wsUrl,
  httpUrl,
  isMocking,
  environment,
  logError,
  logInfo,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { language } = useLanguage();
  const { selectedEquipmentType } = useAppSelector((state) => state.inputs);

  const handleClick = ({ equipmentType, symptom }: SearchExample) => {
    const conversationId = generateId();
    dispatch(
      selectEquipmentTypes({
        selectedTags: [
          {
            name: equipmentType,
            fullName: equipmentType,
            level: 1,
            input: [equipmentType],
          },
        ],
        selectedEquipmentType: equipmentType,
      }),
    );
    dispatch(setActiveFilter(ActiveFilter.EquipmentType));
    dispatch(
      askQuestion({
        conversationId,
        wsUrl,
        httpUrl,
        language,
        isMocking,
        environment,
        logError,
        logInfo,
        equipmentType,
        allEquipmentTags: [equipmentType],
        message: symptom,
        source: QuestionSource.SearchExample,
      }),
    );
    navigate(`${AppRoute.Conversations}/${conversationId}`);
  };

  const authData = useAuthData();
  if (authData === null) {
    return null;
  }
  const examples = isMocking
    ? mockSearchExamples
    : searchExamplesPerTenant[authData.tenantId];
  if (!isDefined(examples)) {
    return null;
  }

  return (
    <div className={styles.container}>
      {selectedEquipmentType === null &&
        examples.map((example) => (
          <button
            key={example.symptom}
            className={styles.example}
            onClick={() => handleClick(example)}
          >
            {example.symptom}
          </button>
        ))}
    </div>
  );
};

export default SearchExamples;
