import groupBy from 'lodash.groupby';

import {
  CustomerSession,
  EquipmentSession,
  SessionSummary,
} from '../../../../types';
import { useAppSelector } from '../../../../store/utils/hooks';

const ONE_DAY_MS = 24 * 60 * 60 * 1000;
const ONE_HOUR_MS = 60 * 60 * 1000;
const ONE_MINUTE_MS = 60 * 1000;
const ONE_SECOND_MS = 1000;

export enum SearchHistoryEntryType {
  Conversation,
  Customer,
}

export enum SearchHistoryEntrySegment {
  Today,
  Yesterday,
  LastWeek,
  OlderThanLastWeek,
}

interface BaseHistoryEntry {
  type: SearchHistoryEntryType;
  id: string;
  label: string;
  createdAt: number;
  segment: SearchHistoryEntrySegment;
}

interface ConversationEntry extends BaseHistoryEntry {
  type: SearchHistoryEntryType.Conversation;
  sessions: SessionSummary[];
}

interface CustomerEntry extends BaseHistoryEntry {
  type: SearchHistoryEntryType.Customer;
}

export type SearchHistoryEntry = ConversationEntry | CustomerEntry;

const getSegment = (sessionCreatedAt: number): SearchHistoryEntrySegment => {
  const now = new Date();
  const msElapsedToday =
    now.getHours() * ONE_HOUR_MS +
    now.getMinutes() * ONE_MINUTE_MS +
    now.getSeconds() * ONE_SECOND_MS +
    now.getMilliseconds();
  const timeDifference = Date.now() - sessionCreatedAt;

  if (timeDifference <= msElapsedToday) {
    return SearchHistoryEntrySegment.Today;
  }
  if (timeDifference <= ONE_DAY_MS + msElapsedToday) {
    return SearchHistoryEntrySegment.Yesterday;
  }
  if (timeDifference <= ONE_DAY_MS * 6 + msElapsedToday) {
    return SearchHistoryEntrySegment.LastWeek;
  }
  return SearchHistoryEntrySegment.OlderThanLastWeek;
};

const getEquipmentHistory = (
  sessions: EquipmentSession[],
): SearchHistoryEntry[] => {
  const sessionsByConversations = groupBy(
    sessions,
    (session) => session.conversationId,
  );

  return Object.entries(sessionsByConversations).map(
    ([conversationId, conversationSessions]) => {
      const lastSession = conversationSessions[conversationSessions.length - 1];

      return {
        type: SearchHistoryEntryType.Conversation,
        id: conversationId,
        label: lastSession.equipmentType.replace('###', ' '),
        createdAt: lastSession.createdAt,
        segment: getSegment(lastSession.createdAt),
        sessions: conversationSessions.map((cs) => ({
          id: cs.id,
          question: cs.question,
        })),
      };
    },
  );
};

const getCustomerHistory = (
  sessions: CustomerSession[],
): SearchHistoryEntry[] => {
  return sessions.map((session) => ({
    type: SearchHistoryEntryType.Customer,
    id: session.id,
    label: session.customerName,
    createdAt: session.createdAt,
    segment: getSegment(session.createdAt),
  }));
};

export type SegmentedHistory = Record<
  SearchHistoryEntrySegment,
  SearchHistoryEntry[]
>;

export const useSearchHistory = (): SegmentedHistory => {
  const { sessions: equipmentSessions } = useAppSelector(
    (state) => state.conversations,
  );
  const { sessions: customerSessions } = useAppSelector(
    (state) => state.customerDetails,
  );

  const conversationHistory = getEquipmentHistory(equipmentSessions);
  const customerHistory = getCustomerHistory(customerSessions);
  const fullHistory = [...conversationHistory, ...customerHistory].sort(
    (e1, e2) => e2.createdAt - e1.createdAt,
  );

  return {
    [SearchHistoryEntrySegment.Today]: fullHistory.filter(
      (entry) => entry.segment === SearchHistoryEntrySegment.Today,
    ),
    [SearchHistoryEntrySegment.Yesterday]: fullHistory.filter(
      (entry) => entry.segment === SearchHistoryEntrySegment.Yesterday,
    ),
    [SearchHistoryEntrySegment.LastWeek]: fullHistory.filter(
      (entry) => entry.segment === SearchHistoryEntrySegment.LastWeek,
    ),
    [SearchHistoryEntrySegment.OlderThanLastWeek]: fullHistory.filter(
      (entry) => entry.segment === SearchHistoryEntrySegment.OlderThanLastWeek,
    ),
  };
};

export const isHistoryEmpty = (
  history: Record<SearchHistoryEntrySegment, SearchHistoryEntry[]>,
): boolean => {
  return Object.values(history).every((entries) => entries.length === 0);
};
