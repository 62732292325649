import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { SessionProps } from '../../../../types';
import { useAppDispatch } from '../../../../store/utils/hooks';
import {
  isUserServiceExpert,
  isSessionFullyLoaded,
  useOnDesktop,
  shouldShowTroubleshooting,
} from '../../../../utils';
import { storeEquipmentSession } from '../../../../store/slices/history.slice';
import EquipmentSessionAnswer from './Answer/EquipmentSessionAnswer';
import Troubleshooting from './Troubleshooting/Troubleshooting';
import DashboardSessionPastJobs from './PastJobs/DashboardSessionPastJobs';
import Carousel from './Carousel/Carousel';
import SearchSuggestions from './SearchSuggestions/SearchSuggestions';
import styles from './DashboardSession.module.scss';

interface Props extends SessionProps {
  containerRef: HTMLDivElement | null;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DashboardSession: React.FC<Props> = ({ containerRef, ...props }) => {
  const { session, isMocking, httpUrl, logError } = props;
  const userServiceExpert = isUserServiceExpert();
  const onDesktop = useOnDesktop();
  const dispatch = useAppDispatch();
  const [sessionSaved, setSessionSaved] = useState(false);
  const showTroubleshooting = shouldShowTroubleshooting(session);

  // This should only be triggered once, when the session is fully loaded.
  // It works because none of the contents are reloaded within any given session.
  // The force feedback feature depends on it, since it is counting total sessions elapsed.
  // useEffect(() => {
  //   if (isSessionFullyLoaded(session)) {
  //     dispatch(
  //       registerNewSession({
  //         scrollContainerRef: containerRef,
  //         sessionId: session.id,
  //       }),
  //     );
  //   }
  // }, [dispatch, session]);

  useEffect(() => {
    if (
      !sessionSaved &&
      isSessionFullyLoaded(session) &&
      !session.savedToHistory
    ) {
      setSessionSaved(true);
      dispatch(
        storeEquipmentSession({
          logError,
          sessionId: session.id,
          mock: isMocking,
          baseUrl: httpUrl,
        }),
      );
    }
  }, [dispatch, session, httpUrl, isMocking, sessionSaved]);

  return (
    <div
      className={clsx(
        userServiceExpert ? styles.container : styles.smallContainer,
      )}
    >
      <h2 className={styles.title} id={`session-${session.id}`}>
        {onDesktop && session.equipmentType !== ''
          ? `${session.equipmentType.replace('###', ' ')} - `
          : ''}
        {session.question}
      </h2>
      <div className={styles.content}>
        <div className={styles.mainInfo}>
          <EquipmentSessionAnswer {...props} />
          {showTroubleshooting && <Troubleshooting {...props} />}
          <SearchSuggestions {...props} />
        </div>
        {userServiceExpert && (
          <div className={styles.additionalInfo}>
            <Carousel {...props} />
            <DashboardSessionPastJobs {...props} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardSession;
